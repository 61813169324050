export const FirebaseConfig = {
	"projectId": "splogystic-5780d",
	"appId": "1:881581601653:web:3b69fd858c0926de6c993c",
	"databaseURL": "https://splogystic-5780d-default-rtdb.firebaseio.com",
	"storageBucket": "splogystic-5780d.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyC5fR1ox-Mf5HQE_yYTb40M9IfBefN1s20",
	"authDomain": "splogystic-5780d.firebaseapp.com",
	"messagingSenderId": "881581601653",
	"measurementId": "G-056R0MGFW4"
};